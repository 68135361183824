import React, { useState, useEffect, useRef } from "react";
import FVLOGOBLACK from '../assets/images/FVLOGOBLACK.png';

const ComingSoon = () => {
  const [timerDays, setTimerDays] = useState("00");
  const [timerHours, setTimerHours] = useState("00");
  const [timerMinutes, setTimerMinutes] = useState("00");
  const [timerSeconds, setTimerSeconds] = useState("00");
  let interval = useRef();

  const startTimer = () => {
    // Calculate the launch date 10 days from now
    const currentDate = new Date();
    const countdownDate = new Date(
      currentDate.getTime() + 10 * 24 * 60 * 60 * 1000
    ).getTime();

    interval.current = setInterval(() => {
      const now = new Date().getTime();
      const distance = countdownDate - now;
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      if (distance < 0) {
        clearInterval(interval.current);
      } else {
        setTimerDays(days.toString().padStart(2, "0"));
        setTimerHours(hours.toString().padStart(2, "0"));
        setTimerMinutes(minutes.toString().padStart(2, "0"));
        setTimerSeconds(seconds.toString().padStart(2, "0"));
      }
    }, 1000);
  };

  useEffect(() => {
    startTimer();
    return () => {
      clearInterval(interval.current);
    };
  }, []);

  return (
    <div className="page-wraper bg-white" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
      <div className="dz-coming-soon style-4">
        <div className="clearfix dz-coming-bx">
          <div className="dz-content text-center">
            <div className="logo-container">
              {/* Add your logo here */}
              <img src={FVLOGOBLACK} alt="Logo" className="logo" />
            </div>
            <h2 className="dz-title ml2">
              <span>We Are Doing Great,</span>
              <br />
              <span>Almost Done...</span>
            </h2>
            <div className="countdown">
              <div className="date">
                <span className="days time">{timerDays}</span>
                <span>Days</span>
              </div>
              <div className="date">
                <span className="hours time">{timerHours}</span>
                <span>Hours</span>
              </div>
              <div className="date">
                <span className="mins time">{timerMinutes}</span>
                <span>Minutes</span>
              </div>
              <div className="date">
                <span className="secs time">{timerSeconds}</span>
                <span>Seconds</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComingSoon;
